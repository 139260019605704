import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Reactmarkdown from "react-markdown"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeaderKontakt from "../../components/header_kontakt"

const ImpressumPage = ({ data }) => {
  const qry = data.allStrapiImpressum.edges[0].node
  const heroImage = qry.picture.localFile.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="Impressum" />
      <HeaderKontakt />
      <Img fluid={heroImage} className="heroimage" />
      <Reactmarkdown>{qry.content}</Reactmarkdown>
    </Layout>
  )
}

export default ImpressumPage

export const query = graphql`
  query ImpressumQuery {
    allStrapiImpressum {
      edges {
        node {
          content
          picture {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
